import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { BallTriangle } from "react-loader-spinner";

function App() {
  let [token, settoken] = useState("");
  let [login, setlogin] = useState("");
  let [tokenState, settokenState] = useState(false);
  let [wrong_token, setwrong_token] = useState(false);
  let [arh_list, setarh_list] = useState([
    {
      date: "2023-05-02T03:55:08.616Z",
      name: "УТ_2023_01_02__22_30.gz",
      size: "2 Gb",
      verified: true,
      link: "./data/Upakovka_Somova/Arhiv/trade_work_2023_04_02__22_30.gz",
    },
    {
      date: "2023-05-02T02:55:08.616Z",
      name: "УТ_2023_05_02__22_30.gz",
      size: "2 Gb",
      verified: false,
      link: "./data/Upakovka_Somova/Arhiv/trade_work_2023_04_02__22_30.gz",
    },
    {
      date: "2023-03-02T03:55:08.616Z",
      name: "Бухгалтерия_2023_04_02__22_30.gz",
      size: "2 Gb",
      verified: true,
      link: "./data/Upakovka_Somova/Arhiv/trade_work_2023_04_02__22_30.gz",
    },
    {
      date: "2023-06-02T03:55:08.616Z",
      name: "Бухгалтерия_2023_05_02__22_30.gz",
      size: "2 Gb",
      verified: false,
      link: "./data/Upakovka_Somova/Arhiv/trade_work_2023_04_02__22_30.gz",
    },
    {
      date: "2023-07-02T03:55:08.616Z",
      name: "ЗУП_2023_04_02__22_30.gz",
      size: "2 Gb",
      verified: true,
      link: "./data/Upakovka_Somova/Arhiv/trade_work_2023_04_02__22_30.gz",
    },
    {
      date: "2023-05-02T03:55:08.616Z",
      name: "ЗУП_2023_05_02__22_30.gz",
      size: "2 Gb",
      verified: false,
      link: "./data/Upakovka_Somova/Arhiv/trade_work_2023_04_02__22_30.gz",
    },
  ]);
  let [klient_info, set_klient_info] = useState({
    name: "Упаковка Сомова",
    bases: ["УТ", "Бухгалтерия", "ЗУП"],
    capacity_on_server: "50Gb",
    busy_on_server: "25Gb",
  });
  let [base_filter, set_base_filter] = useState("");
  let [sort_filter, set_sort_filter] = useState("date");
  let [mod_window, set_mod_window] = useState(false);
  let [delete_arhive, set_delete_arhive] = useState({});
  let [loader_show, setloader_show] = useState(true);

  useEffect(() => {
    if (!sessionStorage.getItem("sesion_id")) {
      setloader_show(false);
    } else {
      fetch("/token", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        // body: JSON.stringify({ token: token }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log("result: ", result);
            if (result.token === "not found") {
              setwrong_token(true);
              setloader_show(false);
            } else {
              console.log("token ok!");
              settokenState(true);
              setarh_list(result.arhive_list);
              set_klient_info(result.klient_info);
              setloader_show(false);
            }
          },
          (error) => {
            console.log("token wrong!!!");
            // this.setState({
            //   isLoaded: true,
            //   error,
            // });
            alert(error);
          }
        );
    }
  }, []);

  function sandKey() {
    setloader_show(true);
    fetch("/token", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify({ token, login }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result: ", result);
          if (result.token === "not found") {
            setwrong_token(true);
            setloader_show(false);
          } else {
            console.log("token ok!");
            settokenState(true);
            setarh_list(result.arhive_list);
            set_klient_info(result.klient_info);
            sessionStorage.setItem("sesion_id", result.sesion_id);
            setloader_show(false);
          }
        },
        (error) => {
          console.log("token wrong!!!");
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
          alert(error);
        }
      );
  }

  function delete_arh() {
    fetch("/delete", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
      body: JSON.stringify({ link: delete_arhive.link }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result: ", result);
          if (result.delete_state === "ok") {
            set_delete_arhive({});
            set_mod_window(false);
            sandKey();
          }
        },
        (error) => {
          alert(error);
        }
      );
  }
  function OutSite() {
    fetch("/outsite", {
      method: "POST",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          sessionStorage.removeItem("sesion_id");
          settoken("");
          setlogin("");
          settokenState(false);
          setloader_show(false);
        },
        (error) => {
          alert(error);
        }
      );
  }
  function delete_arh_question(arhiv) {
    set_mod_window(true);
    set_delete_arhive(arhiv);
  }

  // function download_arh(link) {
  //   fetch("/data", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json;charset=utf-8" },
  //     body: JSON.stringify({ link: link }),
  //   })
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         console.log("result: ", result);
  //         sandKey();
  //       },
  //       (error) => {
  //         console.log("token wrong!!!");
  //         // this.setState({
  //         //   isLoaded: true,
  //         //   error,
  //         // });
  //         alert(error);
  //       }
  //     );
  // }

  let table = base_filter
    ? arh_list
        .filter((elem) => !elem.name.indexOf(base_filter, 0))
        .sort((a, b) => {
          if (a[sort_filter] > b[sort_filter]) {
            return 0;
          } else {
            return 1;
          }
        })
        .map((item, index) => {
          return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{item.date}</td>
              <td>
                {/* <p onClick={() => download_arh(item.link)}>{item.name}</p> */}
                <a download href={item.link}>
                  {item.name}
                </a>
              </td>
              <td>{item.size}</td>
              <td>
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic checkbox toggle button group"
                >
                  <input
                    type="checkbox"
                    class="btn-check"
                    id={"btncheck" + index}
                    checked={item.verified}
                    autocomplete="off"
                  />
                  <label
                    class="btn btn-outline-primary"
                    htmlFor={"btncheck" + index}
                  >
                    {item.verified ? "Проверен" : ""}
                  </label>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => delete_arh_question(item)}
                >
                  Удалить
                </button>
              </td>
            </tr>
          );
        })
    : arh_list
        .sort((a, b) => {
          if (a[sort_filter] > b[sort_filter]) {
            return 0;
          } else {
            return 1;
          }
        })
        .map((item, index) => {
          return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{item.date}</td>
              <td>
                {/* <p onClick={() => download_arh(item.link)}>{item.name}</p> */}
                <a download href={item.link}>
                  {item.name}
                </a>
              </td>
              <td>{item.size}</td>
              <td>
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic checkbox toggle button group"
                >
                  <input
                    type="checkbox"
                    class="btn-check"
                    id={"btncheck" + index}
                    checked={item.verified}
                    autocomplete="off"
                  />
                  <label
                    class="btn btn-outline-primary"
                    htmlFor={"btncheck" + index}
                  >
                    {item.verified ? "Проверен" : ""}
                  </label>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => delete_arh_question(item)}
                >
                  Удалить
                </button>
              </td>
            </tr>
          );
        });

  let bases = klient_info.bases.map((item, index) => {
    return (
      <li
        key={index}
        onClick={() => set_base_filter(item)}
        style={base_filter === item ? { color: "red", fontWeight: "bold" } : {}}
      >
        {item}
      </li>
    );
  });

  let klient_info_blk = (
    <div className="card border-primary mb-3 w-25 p-3 bd-highlight">
      <div className="card-header">{klient_info.name}</div>
      <button className="btn btn-primary" onClick={() => OutSite()}>
        Выйти
      </button>
      <div className="card-body text-primary">
        <h5 className="card-text text-start">Список архивируемых баз:</h5>
        <ul className="text-end">
          {bases}
          <li
            onClick={() => set_base_filter("")}
            style={
              base_filter === "" ? { color: "red", fontWeight: "bold" } : {}
            }
          >
            All
          </li>
        </ul>
        <p className="card-text text-start">
          Обьем памяти на сервере: {klient_info.capacity_on_server}
        </p>
        <p className="card-text text-start">
          Используеться под архивы: {klient_info.busy_on_server}
        </p>
      </div>
    </div>
  );

  let token_ask_form = (
    <div className="position-relative min-vw-100 min-vh-100 ">
      <div className="position-absolute top-50 start-50 translate-middle w-25">
        <div className="mb-3 text-center">
          <p className="fw-bold fs-4">
            {wrong_token ? "Пара логин пароль указаны не верно!" : ""}
          </p>
          <label htmlFor="exampleInputlogin" className="form-label">
            Логин
          </label>
          <input
            type="text"
            className="form-control"
            onChange={(event) => setlogin(event.target.value)}
            value={login}
            id="exampleInputlogin"
          />
          <label htmlFor="exampleInputPassword1" className="form-label">
            Пароль
          </label>
          <input
            type="password"
            className="form-control"
            onChange={(event) => settoken(event.target.value)}
            value={token}
            id="exampleInputPassword1"
          />
        </div>

        <button
          type="submit"
          className="btn btn-primary position-absolute end-0"
          onClick={() => sandKey()}
        >
          Войти
        </button>
      </div>
    </div>
  );

  let del_mod_window = (
    <div class="modal" style={{ display: "block" }}>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Вы подтверждаете удаление архива? </p>
            <p>{delete_arhive.name}</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-danger"
              onClick={() => delete_arh()}
            >
              Удалить
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => set_mod_window(false)}
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return loader_show ? (
    <div className="loader">
      <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#0d6efd"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle=""
        visible={true}
      />
    </div>
  ) : tokenState ? (
    <div className="App">
      <header className="">
        <h1>Архивы</h1>
      </header>
      <main>
        <div className="d-flex flex-row bd-highlight mb-3 p-2">
          <table className="table table-hover w-75">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" onClick={() => set_sort_filter("date")}>
                  Дата
                </th>
                <th scope="col" onClick={() => set_sort_filter("name")}>
                  Имя
                </th>
                <th scope="col" onClick={() => set_sort_filter("size")}>
                  Размер
                </th>
                <th scope="col" onClick={() => set_sort_filter("verified")}>
                  Статус
                </th>
              </tr>
            </thead>
            <tbody>{table}</tbody>
          </table>

          {klient_info_blk}
        </div>
      </main>
      {mod_window ? del_mod_window : ""}
    </div>
  ) : (
    token_ask_form
  );
}

export default App;
